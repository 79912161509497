import { useState, useRef, useEffect } from "react";
import { NavLink } from "react-router-dom";

import { AuthContext } from "../context/AuthContext";
import { AuthDispatchContext } from "../context/AuthContext";
import { useContext } from "react";
import { logout } from "../http";
import { toast } from 'react-toastify';



const Header = ({headerRef}) => {

  const [collapsed, setCollapsed] = useState(true);
  const [stick, setStick] = useState(false);

  const auth = useContext(AuthContext);
  const dispatch = useContext(AuthDispatchContext)
  console.log('auth', auth)

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 30) {
        setStick(true);
      } else {
        setStick(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const onLogout = async (id) => {
  
     try {
         const {data} = await toast.promise(
             logout(id),
             {
               pending: 'Logging out..',
               success: 'Logged Out',
             }
         )
         dispatch({type: 'logout'})
         window.location.reload()
         
     } catch (error) {
         console.log(error)
         toast.error(error.response.data.message, {
             position: "top-right",
             autoClose: 5000,
             hideProgressBar: false,
             closeOnClick: true,
             pauseOnHover: true,
             draggable: true,
             progress: undefined,
             theme: "light",
         })
         return
     }
    
 }

  return (
    <section id="header" style={{position:'fixed'}} ref={headerRef}>
      <div id="header-top" style={{display: stick ? 'none' : 'block'}}>
        <div className="my-container" style={{display: 'flex', justifyContent: 'space-between'}}>
        <a href='https://maps.app.goo.gl/hBy59VsmWfrZWkYu8' target="_blank" className="footer-link footer-contact" style={{color: '#fff'}}>
                                        <i class="fa-solid fa-location-dot"></i> {/* Adjust padding here */}
                                        <span>Hyderabad, India</span>
                                    </a>
                                    <a href='tel:+919502190061' className="footer-link footer-contact" style={{color: '#fff'}}>
                                        <i class="fa-solid fa-phone"></i> {/* Adjust padding here */}
                                        <span>+91 95021 90061</span>
                                    </a>
        </div>
      </div>
      <div className="my-container nav-box" style={{position: 'sticky', top: 0, padding: stick ? '3px 0' : '10px 0'}}>
        <nav className="row nav-b-x-inner">
          <NavLink to={'/'}><img alt="logo" src="/assets/img/logo.png" style={{width: '120px'}} /></NavLink>
          <div className={`nav-mobie-overlay ${collapsed ? 'collapsed' : ''}`}>
            <div className="nav-mobile-sidebar">
              <div className="mobile-nav-header">
              <NavLink to={'/'}><img alt="logo" src="/assets/img/logo.png" style={{width: '120px'}} /></NavLink>
              <div className="btn-gr-border">
              <button className="btn" style={{width: '50px', height: '50px', padding: '0',}} onClick={() => setCollapsed(true)}><i className="fa fa-times"></i></button>
              </div>
              </div>
              <div className="mobile-nav-body">
              <div className="navbar-nav mx-auto nav-mobile">
            <ul className="navbar-nav">
              <li className="nav-item">
                <NavLink to={'/'} onClick={() => setCollapsed(true)}><a className={`nav-link`} >Home</a></NavLink>
              </li>
              <li className="nav-item">
                <NavLink to={'/about-us'} onClick={() => setCollapsed(true)}><a href="#" className={`nav-link`}>About</a></NavLink>
              </li>
              <li className="nav-item">
                <NavLink to={'/gallery'} onClick={() => setCollapsed(true)}><a className={`nav-link`}>Gallery</a></NavLink>
              </li>
              <li className="nav-item">
                <NavLink to={'/refund-policy'} onClick={() => setCollapsed(true)}><a className={`nav-link`}>Refund</a></NavLink>
              </li>
              <li className="nav-item">
                <NavLink to={'/addons'} onClick={() => setCollapsed(true)}><a className={`nav-link`}>Add-Ons</a></NavLink>
              </li>
              <li className="nav-item">
                <NavLink to={'/my-booking'} onClick={() => setCollapsed(true)}><a className={`nav-link`}>My Booking</a></NavLink>
              </li>
              <li style={{marginTop: '20px'}}>
                <NavLink to={'/booknow'} onClick={() => setCollapsed(true)}><button className="btn btn-gradient">Book Now</button></NavLink>
              </li>
              {
                auth._id ? (
                  <li style={{marginTop: '20px'}}>
                <span style={{cursor: 'pointer'}} onClick={onLogout}><p className={`nav-link`}>Logout</p></span>
              </li>
                ) : null
              }
            </ul>
           
          </div>
              </div>
            </div>
          </div>
          <div className="navbar-nav mx-auto nav-desktop">
            <ul className="navbar-nav">
              <li className="nav-item">
                <NavLink to={'/'}><a className={`nav-link`} >Home</a></NavLink>
              </li>
              <li className="nav-item">
                <NavLink to={'/about-us'}><a className={`nav-link`}>About</a></NavLink>
              </li>
              <li className="nav-item">
                <NavLink to={'/gallery'}><a className={`nav-link`}>Gallery</a></NavLink>
              </li>
              <li className="nav-item">
                <NavLink to={'/my-booking'}><a className={`nav-link`}>My Booking</a></NavLink>
              </li>
              <li className="nav-item">
                <NavLink to={'/addons'}><a className={`nav-link`}>Add-Ons</a></NavLink>
              </li>
              <li className="nav-item">
                <NavLink to={'/refund-policy'}><a className={`nav-link`}>Refund</a></NavLink>
              </li>
              {
                auth._id ? (
                  <li className="nav-item">
                <span style={{cursor: 'pointer'}} onClick={onLogout}><p className={`nav-link`}>Logout</p></span>
              </li>
                ) : null
              }
            </ul>
          </div>
 
          <div style={{display: 'flex'}}>
          <div className="toggle">
          <div className="btn-gr-border" style={{borderRadius: '7px'}}>
            <button className="btn " style={{width: '50px', height: '50px', padding: '0',}} onClick={() => setCollapsed(false)}><i className="fa fa-bars"></i></button>
            </div>
          </div>

          <NavLink to={'/booknow'}><button
            className="btn btn-gradient header-btn"
            type="submit"
          >
            Book Now
          </button></NavLink>
          </div>

        </nav>
      </div>
    </section>
  )
}

export default Header;